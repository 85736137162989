nav#search-navigation
{
    background-color: $colorBaseLight;

    .navbar-header
    {
        @include media-breakpoint-down(sm)
        {
            width: 100%;
        }

        .navbar-toggler
        {
            border: 0;
            padding: .5rem 0;
            width: 100%;
            text-align: right;
        }
    }

    .navbar
    {
        margin: 0;
        padding: 0;
        border: 0;

        .navbar-nav
        {
            > li
            {
                > a
                {
                    border-top: 3px solid transparent;
                    border-bottom: 3px solid transparent;
                    color: $colorBaseMid;
                    font-size: 12px;
                    font-family: $altFont;
                    text-transform: uppercase;
                    display: block;
                    padding: .9rem 1.5rem
                }

                &.active > a
                {
                    border-top: 3px solid $colorAccent;
                    border-bottom: 3px solid $colorAccent;
                }

                @include media-breakpoint-up(sm)
                {
                    border-right: 1px solid darken($colorBaseLight, 5%);

                    &:first-child
                    {
                        border-left: 1px solid darken($colorBaseLight, 5%);
                    }
                }
            }
        }
    }

    i
    {
        color: $colorAccent;
    }

    .form-inline
    {
        > select
        {
            height: 2.8em;
            padding-top: 0;
            padding-bottom: 0;
            border: 0;
            font-weight: 300;

            @include media-breakpoint-down(sm)
            {
                width: 100%;
                margin-bottom: .5rem;
            }
        }
    }

    @include media-breakpoint-up(sm)
    {
        .navbar-collapse
        {
            padding-left: 0;
            padding-right: 0;
            justify-content: space-between;
        }
    }
}

aside.page-search-properties
{
    background-color: $colorAccent;
    color: $colorInverse;
    padding: 45px 30px 30px;
    margin-top: 45px;

    label[for=ShowSold]
    {
        margin: 1em 0;
        font-size: 14px;

        @include media-breakpoint-down(sm)
        {
            margin-top: 0;
        }
    }

    @include media-breakpoint-down(sm)
    {
        .row.form-group
        {
            margin-bottom: -8px;

            > div
            {
                margin-bottom: 10px;
            }
        }
    }
}

aside.page-search-inner
{
    input[type=checkbox]:before
    {
        vertical-align: .25em;
    }

    input[type=text], select
    {
        text-align: center;
        font-weight: 300;
    }

    label[for=ShowSold]
    {
        margin-bottom: 0;
    }
}

.search-list-page
{
    display: none;

    &.active
    {
        display: block;
    }
}