.news-item
{
    margin-bottom: 2.5rem;
    text-align: left;

    + .news-item
    {
        margin-top: 5rem;

        @include media-breakpoint-down(sm)
        {
            margin-top: 3rem;
        }
    }

    h3
    {
        font-weight: bold;
        margin-top: 0;
        margin-bottom: .25rem;
        padding-bottom: 0;

        > a
        {
            font-weight: bold;
            border-bottom: 0;
        }
    }

    img.embed-responsive-item
    {
        object-fit: cover;
    }

    :link
    {
        text-decoration: none !important;
    }

    .list-inline-item:not(:last-child)
    {
        margin-right: .25rem;
    }

    @include media-breakpoint-down(md)
    {
        .promo-block-item
        {
            height: auto;
        }
    }

    a.promo-block-image
    {
        display: block;
        border-bottom: 0;

        > img
        {
            max-height: 160px;
            margin: 0 auto 0 0;
            display: block;

            &.promo-block-placeholder
            {
                padding: 2em;
                background-color: $colorDarkGrey;
            }
        }
    }

    .list-inline-item
    {
        display: inline-flex;
        vertical-align: middle;
        padding: 0 .5rem;

        &:first-child
        {
            padding-left: 0;
        }

        &:last-child
        {
            padding-right: 0;
        }
    }
}

header.news-item
{
    margin-bottom: 1rem;
    text-align: left;
    margin-top: 0;

    h1
    {
        margin: 0;
    }
}

a.news-badge,
span.news-badge
{
    @include uppercaseText();
    display: inline-block;
    font-size: .75rem;
    font-weight: bold;
    line-height: 1;
    color: $colorInverse;
    background-color: $colorBaseMid;
    padding: .333rem .75rem .4rem;
    text-align: center;

    &.news-badge-date
    {
        color: $colorAlternate;
        background-color: $colorBaseLight;
    }

    &.news-badge-category
    {
        background-color: $colorAlternate;
    }

    &.news-badge-staff
    {
        position: relative;
        color: $colorAlternate;
        padding-left: 2.5rem;

        > img
        {
            position: absolute;
            width: 2rem;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
        }
    }
}

.news-content
{
    margin: 0;
    text-align: left;

    img
    {
        max-width: 100%;
    }

    p:last-child
    {
        margin-bottom: 0;
    }
}

a.news-read-more,
span.news-read-more
{
    @include arrowButton();
    @include uppercaseText();
    text-decoration: none;
    padding: 15px 70px 15px 30px;
    font-family: $altFont;

    &:hover
    {
        background-color: $colorAccent;
        color: $colorInverse;
    }

    &:after
    {
        padding: 1.1em;
    }
}

a.news-view-more
{
    margin-top: 1rem;
    float: right;

    @include media-breakpoint-down(sm)
    {
        float: none;
        width: 100%;
    }
}

.news-photo
{
    text-align: left;

    @include media-breakpoint-up(sm)
    {
        float: left;
    }

    @include media-breakpoint-down(xs)
    {
        margin-bottom: 1rem;
    }

    > img
    {
        max-height: 160px;
    }

    .embed-responsive-item
    {
        max-width: 100%;
    }
}


#latest-news
{
    li
    {
        margin-bottom: 1.5rem;
        line-height: 1.25;
    }

    a
    {
        color: $colorAccent;
        border-bottom: 0;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1rem;
        font-family: $altFont;
    }
}

@include media-breakpoint-only(sm)
{
    .news-aside
    {
        max-width: 24rem;
    }
}
