.btn-secondary
{
    @include button-variant($secondary, $colorGrey);
}

a:hover,
a:focus,
a:active
{
    text-decoration: none;
}

.text-white a
{
    color: $colorInverse;
}

p + h3
{
    margin-top: 2rem;
}

label
{
    font-weight: bold;
}

img.lazyload
{
    width: 100%;
    max-width: 100%;
    display: block;
}

.text-light .table
{
    color: $light;
}

.input-group-prepend > .input-group-text
{
    border-right-color: $white;
    z-index: 0;
}

.input-group-append > .input-group-text
{
    border-left-color: $white;
    z-index: 0;
}

.input-group button
{
    background-color: $white;
    z-index: 0;
    border: 1px solid $input-border-color;
    border-left-width: 0;
    line-height: 1;
    padding: 0 1.25em;
}

.breadcrumb-item + .breadcrumb-item::before
{
    display: block;
    float: left;
}



.btn
{
    width: 100%;
    display: block;
    border-radius: 0;
    border: 3px solid $colorInverse;
    font-family: $altFont;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    padding: 10px 4px;

    &.btn-transparent
    {
        background-color: transparent;

        &:hover,
        &:focus
        {
            background-color: rgba(0,0,0,0.5);
        }
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:active:hover
    {
        border: 3px solid $colorInverse;
    }

    &:last-child
    {
        margin-bottom: 0;
    }
}

.btn-icon
{
    position: relative;
    background-color: $colorInverse;
    color: $colorBaseMid;
    padding: 15px 70px 15px 20px;
    border: 0;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;

    > .icon
    {
        width: 50px;
        font-size: 18px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        background-color: $colorAccent;
        color: $colorInverse;

        > i
        {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
        }
    }

    &:hover,
    &:active,
    &:focus,
    &:hover:active
    {
        border: 0;
        background-color: $colorAccent;
        color: $colorInverse;
    }
}

input[type=checkbox]
{
    visibility: hidden;
    display: inline-block;
    font-weight: normal;
    margin: 4px 0 0 6px;
    line-height: normal;
    color: currentColor;

    &:before
    {
        content: "\f096";
        font-family: FontAwesome;
        visibility: visible;
    }

    &:checked:before
    {
        content: "\f046";
    }
}

.la-arrow-right:before
{
    content: "\f054" !important;
}

.la-arrow-left:before
{
    content: "\f053" !important;
}