header#page-header
{
    text-align: left;
    background-color: $colorBaseMid;
    color: $colorInverse;
    padding: 30px;
    font-family: $altFont;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;

    a
    {
        color: $colorInverse;
        border: 0;

        &.btn
        {
            border: 3px solid $colorInverse;
        }

        &.btn-header
        {
            margin-bottom: 1em;
            max-width: 17.5rem;

            &:last-child
            {
                margin-bottom: 0;

                @include media-breakpoint-between(sm, md)
                {
                    margin-bottom: 1em;
                }
            }

            @include media-breakpoint-down(sm)
            {
                max-width: 15rem;
            }

            @include media-breakpoint-down(xs)
            {
                margin-left: auto;
                margin-right: auto;
            }

            @include media-breakpoint-between(xs, md)
            {
                display: inline-block;
                margin-left: .5em;
                margin-right: .5em;
            }
        }

        &.btn-throb
        {
            animation: throb 2s ease-in-out infinite;
        }
    }

    &.page-header-home
    {
        background-image: url(/site/img/header/home.jpg);

        @include media-breakpoint-up(lg)
        {
            height: 780px;
        }
    }

    &.page-header-packages
    {
        background-image: url(/site/img/header/packages.jpg);
    }

    #page-header-logo
    {
        margin: 0 auto;
        display: block;
        max-width: 17.5rem;

        @include media-breakpoint-down(sm)
        {
            max-width: 15rem;
        }
    }

    #page-header-phone > a
    {
        border-bottom: 0;
        font-size: 15px;
        letter-spacing: 1.5px;
    }

    .header-social
    {
        margin-bottom: 0.3333em;
        font-size: 18px;
        text-align: center;
    }
}

.header-wrapper
{
    display: grid;
    gap: 1.75rem;
    grid-template-areas: "nav" "lgo" "btn";
    grid-auto-columns: 1fr;

    > .nav-header
    {
        grid-area: nav;
    }

    > .logo-wrapper
    {
        grid-area: lgo;
    }

    > .header-buttons
    {
        grid-area: btn;
    }

    @include media-breakpoint-only(xs)
    {
        grid-template-rows: 70px max-content max-content;
    }

    @include media-breakpoint-between(sm, md)
    {
        grid-template-areas: "lgo nav" "btn btn";
    }

    @include media-breakpoint-up(lg)
    {
        grid-template-areas: "lgo btn nav";
        grid-template-columns: 17.5rem 17.5rem 1fr;
        align-content: end;

        > .header-buttons
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

.nav-header
{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 1000;
    top: 30px;
    right: 30px;
    margin: 0 auto;
    width: 100%;

    @include media-breakpoint-up(md)
    {
        justify-content: end;
    }

    &.expanded
    {
        position: fixed;
        justify-content: end;
    }
}

button#toggle-navigation
{
    color: $colorInverse;
    outline: 0;
    margin-left: 1rem;
    padding: .5rem;
}

nav#page-nav
{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 300px;
    background-color: $colorBaseMid;
    box-shadow: 0 0 15px 5px rgba(black, 0.33);
    z-index: 100;
    transform: translateX(315px);
    will-change: transform;
    transition: transform ease-in-out 0.25s;
    font-family: $mainFont;

    &.visible
    {
        transform: translateX(0);
    }

    > ul
    {
        margin: 130px 0 0 0;
    }

    ul
    {
        list-style: none;
        padding: 0;

        &.dropdown-menu
        {
            position: static;
            text-align: center;
            width: 100%;
            background-color: transparent;
            float: none;
            margin-bottom: 15px;
            border: 0;

            > li
            {
                margin: 0;

                > a
                {
                    margin: 0;
                    font-size: .75rem;
                }
            }
        }

        > li
        {
            margin: 15px auto;

            > a
            {
                letter-spacing: 3px;
                border-bottom: 0;
                padding: 6px 0;
                margin: 15px auto;
                position: relative;
                color: $colorInverse;
                text-transform: uppercase;
                display: block;
                font-weight: 400;

                &:hover,
                &[data-id='15']
                {
                    color: $colorAccent;
                    background-color: transparent;
                }

                > span
                {
                    display: inline-block;
                }
            }

            &.external > a
            {
                color: $colorAccent;
            }

            &.active > a > span
            {
                border-bottom: 3px solid $colorAccent;
                padding: 0 10px;
            }

            &.dropdown
            {
                > a
                {
                    > span
                    {
                        position: relative;

                        &:after
                        {
                            content: "";
                            display: block;
                            width: 1rem;
                            height: 1rem;
                            background-size: contain;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M 4.21875 10.78125 L 2.78125 12.21875 L 15.28125 24.71875 L 16 25.40625 L 16.71875 24.71875 L 29.21875 12.21875 L 27.78125 10.78125 L 16 22.5625 Z' fill='%23fff'/%3E%3C/svg%3E");
                            position: absolute;
                            top: 50%;
                            left: calc(100% + .5rem);
                            transform: translateY(-55%);
                        }
                    }

                    &:hover > span:after
                    {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M 4.21875 10.78125 L 2.78125 12.21875 L 15.28125 24.71875 L 16 25.40625 L 16.71875 24.71875 L 29.21875 12.21875 L 27.78125 10.78125 L 16 22.5625 Z' fill='%23F58220'/%3E%3C/svg%3E");
                    }
                }

                &.show > a
                {
                    margin-bottom: 0;

                    > span
                    {
                        &:after
                        {
                            transform: rotate(180deg) translateY(45%);
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M 4.21875 10.78125 L 2.78125 12.21875 L 15.28125 24.71875 L 16 25.40625 L 16.71875 24.71875 L 29.21875 12.21875 L 27.78125 10.78125 L 16 22.5625 Z' fill='%23F58220'/%3E%3C/svg%3E");
                        }
                    }
                }
            }
        }
    }
}

section#home-valuation
{
    margin-bottom: 85px;
    animation: flyin 2s ease-in-out;

    @include media-breakpoint-only(md)
    {
        margin-bottom: 45px;
    }

    h2
    {
        font-size: 28px;
        line-height: 1.3333;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 0.5em;
        font-weight: bold;
        margin-top: 0;
    }
}


@keyframes throb
{
    0%
    {
        transform: scale(1);
    }

    50%
    {
        transform: scale(1.08);
    }

    100%
    {
        transform: scale(1);
    }
}


@keyframes flyin
{
    0%
    {
        transform: translateX(-100vw);
    }

    100%
    {
        transform: translateX(0);
    }
}

#home-content
{
    margin-top: 2.5rem;
}