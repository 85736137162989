@import "inc/variables";
@import "fonts/lato";
@import "fonts/montserrat";
@import "inc/bootstrap/critical";

@import "inc/overrides";
@import "inc/editor";
@import "inc/js-helpers";
@import "inc/ckstyles";

html
{
    background-color: $colorAccent;
}

body
{
    background-color: $colorInverse;
    text-align: center;
}

main#page-content
{
    padding: 48px 0;
}

@import "parts/typography";
@import "parts/skiplinks";
@import "parts/header";
@import "parts/social";
@import "parts/search";
@import "parts/sales";
@import "parts/news";
@import "parts/property-item";
@import "parts/errors";