body
{
    text-align: center;
    font-weight: 300;
}

h1, h3
{
    font-size: 28px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 1.5rem;
    margin-bottom: 1em;
    color: $colorAccent;

    > small
    {
        margin-top: 0.5em;
        font-size: 0.5em;
        letter-spacing: 2px;
        font-weight: bold;
        display: block;
        color: $colorBaseMid;
    }
}

h2
{
    font-size: 20px;
    line-height: 1.75;
    margin-bottom: 1em;
    font-weight: 300;
}

.heading-bordered
{
    position: relative;
    clear: both;

    > span
    {
        display: inline-block;
        background-color: $colorInverse;
        z-index: 1;
        transform: translateZ(0);
        padding: 0 1em;
    }

    &:before
    {
        content: "";
        position: absolute;
        width: 100%;
        border-bottom: 1px solid darken($colorInverse, 10%);
        left: 0;
        top: 0.5em;
        z-index: 0;
    }
}

p
{
    margin-bottom: 2em;
}

a
{
    font-weight: normal;
    border-bottom: 1px solid $colorAccent;

    &:focus,
    &:hover
    {
        text-decoration: none;
        color: darken($colorAccent, 15%);
        border-bottom-color: darken($colorAccent, 15%);
    }
}

li
{
    list-style-position: inside;
}

.home-content .ck-grid-row
{
    margin-top: 4rem;
}