
section#home-sales
{
    margin: 0;
    text-align: center;
    font-family: $altFont;

    h2
    {
        margin-top: 30px;
        font-size: 28px;
        line-height: 1.3333;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 0.5em;
        font-weight: bold;
        color: $colorAccent;

        > small
        {
            font-size: 0.5em;
            font-weight: bold;
            display: block;
            color: $colorAccent;
            margin-top: 10px;
        }

        @include media-breakpoint-down(xs)
        {
            font-size: 24px;
        }
    }
}

.sales-option-wrap
{
    display: flex;
    position: relative;
    text-transform: uppercase;
    font-weight: bold;
    margin: 30px -15px 0;
    flex-wrap: wrap;
    color: $colorInverse;
    justify-content: center;

    > .sales-option
    {
        flex-grow: 1;
        background: $colorAccent;
        margin: 15px;
        font-size: 36px;
        line-height: 32px;
        letter-spacing: 2px;
        padding: 1em 1em 80px 1em;
        width: 300px;
        height: 220px;
        position: relative;

        @include media-breakpoint-down(sm)
        {
            margin: 15px 0;
        }

        h4
        {
            position: absolute;
            top: 40px;
            left: 0;
            right: 0;
            text-align: left;
            width: 240px;
            margin: 0 auto 0.33em;
            line-height: .9;

            > strong
            {
                color: $colorBaseMid;
                display: block;
            }
        }

        p
        {
            position: absolute;
            bottom: 68px;
            left: 0;
            right: 0;
            width: 240px;
            margin: 0 auto 0.33em;
            text-align: right;

            > small
            {
                font-size: 14px;
                font-weight: bold;
                color: $colorBaseMid;
                letter-spacing: 0;
                position: relative;
                top: -14px;
            }

            > sub
            {
                font-size: 10px;
                bottom: 0;
            }
        }

        em
        {
            font-style: normal;
            font-weight: 300;
        }

        a
        {
            border-bottom: 0;
            line-height: 1;
            display: inline-block;
            font-size: 10px;
            letter-spacing: 1.5px;
            position: absolute;
        }

        .option-desc + a
        {
            background-color: $colorBaseMid;
            padding: 1.5em 6.25em 1.5em 2.5em;
            bottom: 32px;
            left: 50%;
            transform: translateX(-50%);
            width: 60%;
            min-width: 240px;
            color: $colorInverse;

            > span
            {
                position: absolute;
                top: 0;
                right: 0;
                width: 3.75em;
                height: 100%;
                text-align: center;
                padding: 1.5em 0;
                background-color: lighten($colorBaseMid, 10%);
            }
        }

        .option-desc + a + a
        {
            background-color: $colorInverse;
            color: $colorAccent;
            padding: 1em 2em;
            top: 100%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: bold;

            &:hover
            {
                color: $colorBaseMid;
            }
        }

        @include media-breakpoint-down(lg)
        {
            flex-grow: 0;
        }
    }
}

header#page-header
{
    .sales-option-wrap
    {
        margin: 0;

        > .sales-option
        {
            @include media-breakpoint-up(lg)
            {
                margin-left: 0;
                margin-right: 0;

                &:first-child
                {
                    margin-top: 0;
                }

                &:last-child
                {
                    margin-bottom: 30px;
                }
            }
        }
    }

    .option-desc
    {
        top: 78px;

        + a + a
        {
            color: $colorAccent;

            &:hover
            {
                color: $colorBaseMid;
            }
        }
    }
}


section#package-benefits
{
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 40px 0;

    .benefit-item
    {
        background-color: $colorInverse;
        color: $colorAccent;
        text-align: center;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: bold;
        max-width: 280px;
        margin: 0 auto;

        > img
        {
            max-width: 100%;
            border-bottom: 5px solid $colorAccent;
        }

        > p
        {
            padding: 15px 10px;
            position: relative;
            line-height: 1.3333;
            margin-bottom: 2em;

            &:after
            {
                content: "";
                position: absolute;
                bottom: 100%;
                left: 50%;
                border: 15px solid transparent;
                border-bottom: 15px solid $colorAccent;
                width: 0;
                height: 0;
                transform: translateX(-50%);
            }

            @include media-breakpoint-only(sm)
            {
                min-height: 90px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            @include media-breakpoint-only(lg)
            {
                min-height: 90px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
}

.packages-wrapper
{
    margin-top: 3rem;
}