.property-item-wrapper
{
    margin-bottom: 1.5em;
    box-shadow: 0 0 10px 0 rgba($colorBaseMid, 0.1);
    border: 1px solid $colorInverse;
    display: block;
    transition: box-shadow ease-in 0.5s;
    position: relative;
    overflow: hidden;
    min-height: 12rem;

    &:hover,
    &:active,
    &:focus,
    &:hover:active
    {
        border-bottom-color: $colorInverse;
        box-shadow: 0 0 8px 2px rgba($colorBaseMid, 0.15);
        outline: 0;

        .item-view-more
        {
            border: 0;
            background-color: $colorAccent;
            color: $colorInverse;
        }
    }

    div.item-actions
    {
        position: absolute;
        bottom: 0;
        width: 100%;

        @include media-breakpoint-down(md)
        {
            display: none;
        }
    }

    .item-view-more
    {
        position: absolute;
        background-color: $colorBaseLight;
        width: auto;
        display: inline-block;
        padding-right: 70px;
        padding-left: 30px;
        border: 0;
        bottom: 0;
        right: 0;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 1px;

        > .icon
        {
            width: 40px;
            background-color: $colorAccent;
            color: $colorInverse;
        }

        @include media-breakpoint-down(md)
        {
            padding-top: 7.5px;
            padding-bottom: 7.5px;
            padding-left: 20px;
            padding-right: 60px;
        }
    }

    div.item-social
    {
        @include media-breakpoint-down(md)
        {
            font-size: .75rem;
        }

        @include media-breakpoint-up(md)
        {
            left: 44%;
            position: absolute;
            bottom: 0;
        }
    }

    img
    {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;

        @include media-breakpoint-between(sm, md)
        {
            position: absolute;
            top: 0;
            left: 0;
        }

        @include media-breakpoint-only(sm)
        {
            width: 50%;
        }

        @include media-breakpoint-only(md)
        {
            width: 41.6667%;
        }
    }
}

div.item-social
{
    .jssocials-share-link
    {
        border-radius: 50%;
        border: 0;
    }
}

.property-item
{
    .row > div
    {
        padding: 0;
        position: static;
    }

    h3
    {
        font-size: 16px;
        width: 75%;
        margin: 1.5em auto 1em;
        padding: 0;
        border-bottom: 0;
    }

    .item-details
    {
        @include media-breakpoint-between(sm, md)
        {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    p.item-description
    {
        margin: 0 auto;
        width: 75%;
        font-size: 11px;
        text-transform: uppercase;
        color: $colorBaseMid;
        font-family: $altFont;
        font-weight: normal;
        margin-bottom: 60px;

        @include media-breakpoint-down(md)
        {
            display: none;
        }
    }

    .item-banner
    {
        position: absolute;
        top: 100px;
        left: -2.3333em;
        width: 180px;
        background-color: rgba($colorAccent, 0.9);
        color: $colorInverse;
        z-index: 1;
        font-family: $altFont;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        padding: 0.6667em 0;
        transform: rotate(-45deg);
        transform-origin: top left;
    }
}

div.item-attributes
{
    margin: 0 auto 1em;
    color: $colorBaseMid;

    > div
    {
        vertical-align: middle;
        padding-right: 20px;
        border-right: 1px solid darken($colorInverse, 6.667%);
        margin-right: 20px;

        &.item-attribute-icons
        {
            font-size: 10px;

            > div
            {
                width: 3em;
            }
        }

        &.item-attribute-price
        {
            font-weight: bold;
            letter-spacing: 1px;
        }

        &:last-child
        {
            padding-right: 0;
            border-right: 0;
            margin-right: 0;
        }
    }

    div
    {
        display: inline-block;
    }

    i
    {
        display: block;
        font-size: 1.8em;

        &.fa-bath
        {
            margin-bottom: 0.15em;
        }

        &.vs-sofa
        {
            margin-bottom: 0.075em;
        }
    }
}

section#property-details
{
    .bullet-row
    {
        font-family: $altFont;
        color: $colorAccent;
        font-size: 0.875em;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: normal;

        > li
        {
            margin-bottom: 1.5em;
            line-height: 1.25;

            @include media-breakpoint-down(sm)
            {
                margin-bottom: 0.9em;
            }
        }
    }

    .item-attributes
    {
        margin-bottom: 50px;
    }

    .jssocials-share-logo
    {
        font-size: 1rem;
    }
}

#property-actions
{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

#toggle-sections
{
    margin-bottom: 3rem;
}