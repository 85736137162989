div.social > a
{
    background-color: lighten($colorAccent, 10%);
    color: $colorInverse;
    display: inline-block;
    width: 2em;
    height: 2em;
    text-align: center;
    line-height: 2em;
    margin-right: 0.5em;
    border-radius: 50%;
    transition: background-color ease-in-out 0.1s;

    &:last-child
    {
        margin-right: 0;
    }

    &:hover,
    &:active
    {
        background-color: $colorAccent;
    }
}
