h1,
h2,
h3,
h4,
h5,
h6
{
    @include uppercaseText();
}

h3
{
    padding-bottom: .5rem;
    margin-bottom: 1rem;
}

h4
{
    font-weight: bold;
}

summary
{
    display: block;

    &::-webkit-details-marker
    {
        display: none;
    }
}

p:last-child
{
    margin-bottom: 0;
}
